import * as React from "react";
import { useState, useContext } from "react";
import {
  Form,
  Container,
  Row,
  Col,
  Card,
  Button,
  Alert,
} from "react-bootstrap";
import styled from "styled-components";
import { Heading2, Heading1 } from "../components/common/ntmwd";
import { FirebaseContext } from "../components/Firebase";
import { navigate } from "gatsby";
import { WmkSeo } from "wmk-seo";
import { colors } from "../vars/palette";

const Styled: { [key: string]: Function } = {};
Styled.Form = styled(Card)`
  margin: 4vh 0;
  padding: 6vh 4vh;
`;

const LoginPage = () => {
  const [formValues, setFormValues] = useState({ email: "", password: "" });
  const [errorMsg, setErrorMsg] = useState(null);
  const { firebase } = useContext(FirebaseContext);
  const handleSubmit = (e) => {
    e.preventDefault();
    firebase
      .login({ email: formValues.email, password: formValues.password })
      .then((res) =>
        res ? navigate("/") : setErrorMsg("Error: Check username/password")
      )
      .catch((err) => setErrorMsg(err));
  };
  const handleChange = (e) => {
    e.persist();
    setFormValues((current) => ({
      ...current,
      [e.target.name]: e.target.value,
    }));
  };
  return (
    <Container>
      <WmkSeo.Meta
        title={"Login"}
        siteTitle={"NTMWD Board Portal"}
        twitterImageUrl={''}
        twitterHandle={''}
        ogImageUrl={''}
        baseUrl="https://board.ntmwd.com"
        slug="login"
      />
      <Row style={{ justifyContent: "center" }}>
        <Col xs={12} md={8} lg={6}>
          <Heading1
            din
            style={{ marginTop: "6vh", color: colors.hex("primary") }}
          >
            Board Portal Login
          </Heading1>
          <Styled.Form>
            <Heading2 din>Welcome!</Heading2>
            <Form onSubmit={handleSubmit} autoComplete="on">
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Username</Form.Label>
                <Form.Control
                  name="email"
                  value={formValues.email}
                  type="email"
                  placeholder="Enter username"
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  name="password"
                  value={formValues.password}
                  type="password"
                  placeholder="Enter password"
                  onChange={handleChange}
                  autoComplete="off"
                />
              </Form.Group>
              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Form>
          </Styled.Form>
        </Col>
      </Row>
      {errorMsg ? (
        <Row style={{ justifyContent: "center" }}>
          <Col>
            <Alert variant={"danger"}>{errorMsg}</Alert>
          </Col>
        </Row>
      ) : null}
    </Container>
  );
};
export default LoginPage;
